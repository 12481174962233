import React, { useEffect, useState,useRef } from 'react'
import "rsuite/dist/rsuite.min.css"
import './customers.css'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Admin/sidebar'
import { auth } from '../Auth/firebase'
import { useModelState } from '../custom_hooks/Custom'
import {Modal} from 'rsuite'
import {useNavigate} from 'react-router-dom'
import Adminpopup from '../Popup/Adminpopup'
import QRCode from 'qrcode.react';

import { useReactToPrint } from 'react-to-print';



const Vehiclecustomers = () => {
    const componentRef = useRef();
    const [phone,setphone]=useState('');
    const [email,setemail]=useState('');
    const [vehicle,setvehicle]=useState('');
    const {isOpen,open,close}=useModelState();
    const [vehicletags,setvehicletags]=useState([]);

    const [searchphone,setsearchphone]=useState();

    const [filtered,setfiltered]=useState([]);
    const [isfiltered,setisfiltered]=useState(false);
   
    const [popupOpen, setPopupOpen] = useState(false);
    
    const navigate=useNavigate();

    const handleClosePopup = () => {
        setPopupOpen(false);
      };
    
    
    const fetchusercred=async()=>{
        const response= await fetch(`https://doapi.mypin.in/getallvehicletags`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*' 
        }
        }
        );
        const data= await response.json();
        
        setvehicletags(data);
        
      }

const searchcustomer=()=>{
    setfiltered(vehicletags.filter(data => data.phone === searchphone));
    setisfiltered(true);
    open();
    console.log(filtered);
}
const performaction=(uid)=>{
    setfiltered(vehicletags.filter(data => data._id === uid));
    setisfiltered(true);
    open();
    console.log(filtered);
}

const updatedata=(userid)=>{
    close();
   setPopupOpen(true);
    const data={
        vehicle:vehicle,
        email:email,
        phone:phone
    }
    fetch(`https://doapi.mypin.in/updatevehicleuserdata/${userid}`,{
        method:"POST",
        headers:{
          'content-type':'application/json'
        },
        body:JSON.stringify(data)
      })
      .then(res=>{
         res.json();
        
         
      })
      .catch(err=>{
        console.log(err);
      })
      setTimeout(() => {
        setPopupOpen(false);
      }, 3000);
}
const deleteuser=(uid)=>{
    close();
    setPopupOpen(true);

    console.log(uid);
    fetch(`https://doapi.mypin.in/deletevehicletaguser/${uid}`,{
        method:"POST",
        headers:{
          'content-type':'application/json'
        },
      })
      .then(res=>{
        res.json();
      })
      .catch(err=>{
        console.log(err);
      })   
      
      setTimeout(() => {
        setPopupOpen(false);
      }, 3000);
    

}

    useEffect(()=>{
        fetchusercred();
       if(!auth.currentUser){
        navigate('/login')
       }
    });
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });

  return <>

<div className='bck-box5'>

<div className={`popup-overlay ${popupOpen ? 'open' : ''}`} onClick={handleClosePopup}></div>
      <Adminpopup isOpen={popupOpen} onClose={handleClosePopup} />
  <div className='isres'>
  <Navbar/>
  </div>
      <div className='customer-box'>
      <div class="search-container">
    <input 
    type="text" 
    class="search-input" 
    id="searchInput" 
    placeholder="Phone number" 
    value={searchphone} 
    onChange={(ev)=>{setsearchphone(ev.target.value)}} 
    onInputCapture={(ev=>setsearchphone(ev.target.value))}
    />
    <button class="search-button" onClick={searchcustomer} style={{backgroundColor:'#16AEE8'}}>Search</button>
    <Modal open={isOpen} onClose={close}
    className='modal'
    style={{backgroundColor:'rgb(0,0,0,0.5)'}}
    >
    <Modal.Header className='searchoutput'>Search Output</Modal.Header>
    <Modal.Body
    className='modalbody'
    >
    {isfiltered && 
  <div id="results">
   <div className=''>
        <div className='form-head'>
        </div>
        {filtered && 
        filtered.map((data,idx)=>{
            return <>
            <div key={idx}>
            <div class="input-container">
        <input type="text" id="input2"  value={vehicle} onChange={ev=>setvehicle(ev.target.value)} className='ip' placeholder={(data.vehicle).toUpperCase()} />
        <label for="input2 edit" className='edit'>Vehicle No.</label>
      </div>
    
      <div class="input-container">
        <input type="text" id="input1"  value={phone} onChange={ev=>setphone(ev.target.value)} className='ip' placeholder={data.phone} />
        <label for="input1 edit" className='edit'>Phone No.</label>
      </div>
            <div class="input-container">
        <input type="text" id="input1" value={email} onChange={ev=>setemail(ev.target.value)} className='ip'placeholder={data.email} />
        <label for="input1 edit" className='edit'>Email Id</label>
      </div>
      <button className='updatebtn2' onClick={()=>updatedata(data._id)}>Update</button>
      <button className='deletebtn2' onClick={()=>deleteuser(data._id)}>Delete User</button>
      <span className='ptres'>
      <button className='deletebtn2 ptbtn' onClick={()=>handlePrint()} style={{backgroundColor:'#16AEE8'}}>Print Tag</button>
      </span>
      </div>

      <div style={{display:'none'}}>
        <div  ref={componentRef}>
         <div >
          <div className='generatedqrcode'><QRCode value={`https://do.mypin.in/vehiclescanner/${data.userid}`} className='main-codep2' /></div>
          <img src="https://i.postimg.cc/yY8FnZPd/emptyvehicle.png" alt="tagprint" className='template'/>
         </div>
    </div>
  </div>
            </>
        })
        }
    </div>

  </div>
  
  }
    </Modal.Body>
    <Modal.Footer>
    </Modal.Footer>
  </Modal>
  
  </div>

      <table>
  <tr>
    <th>S.No.</th>
    <th>Phone</th>
    <th>Email Id</th>
    <th>Vehicle No.</th>
    <th>Status</th>
  </tr>
        { vehicletags && vehicletags.map((data,idx)=>{
            return <>
             <tr key={idx}>
    <td>{idx+1}.</td>
    <td>{data.phone}</td>
    <td>{data.email}</td>
    <td>{(data.vehicle).toUpperCase()}</td>
    <td><div className='deletebtn' style={{backgroundColor:'#16AEE8'}} onClick={()=>{performaction(data._id)}}>Action</div></td>
  </tr>
            </>
        })}

</table>

      </div>
</div>

  </>
}

export default Vehiclecustomers
