import React, { useState,useRef, useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import QRCode from 'qrcode.react';
import './Doorbellgenerator.css'

import { useReactToPrint } from 'react-to-print';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../Auth/firebase';
import Sidebar from '../Admin/sidebar';


const Doorbellgenerator = () => {
  const navigate=useNavigate();

    const [house ,sethouse]=useState('');
    const [phone,setphone]=useState('');
    const [email, setemail] = useState('');
    const userid=`mypin${(phone).substring(4,10)}`;
  const [generatedQRCode, setGeneratedQRCode] = useState('');

  const componentRef = useRef();

  useEffect(()=>{
    if(!auth.currentUser){
      navigate('/login')
    }
  })

  const generateQRCode = (ev) => {

    ev.preventDefault();

    const data={
      userid:userid,
      house:house,
      email:email,
      phone:phone

    }
    setGeneratedQRCode(`https://do.mypin.in/doorbellscanner/${userid}`);

    fetch('https://doapi.mypin.in/savedoorbelldata',{
      method:"POST",
      headers:{
        'content-type':'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body:JSON.stringify(data)
    })
    .then(res=>{
      res.json();
    })
    .catch(err=>{
      console.log(err);
    })
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return <>
  <div className='bck-boxdg'>
  <div className='isres'>
  <Navbar/>
  </div>
  <div className='generator-form'>
    <form onSubmit={(ev)=>generateQRCode(ev)}>
    <div className='form'>
        <div className='form-head'>
            Enter the details to generate your Doorbell tag.
        </div>
    <div class="input-container">
    <input type="text"  id="input1" required value={phone} onChange={ev=>setphone(ev.target.value)} className='ip' />
    <label for="input1" style={{color:'#16AEE8'}}>Phone No.</label>
  </div>

  <div class="input-container">
    <input type="text" id="input2" required value={house} onChange={ev=>sethouse(ev.target.value)} className='ip'/>
    <label for="input2" style={{color:'#16AEE8'}}>House No.</label>
  </div>
  <div class="input-container">
    <input type="email" id="input3" required value={email} onChange={ev=>setemail(ev.target.value)} className='ip' />
    <label for="input2" style={{color:'#16AEE8'}}>Email Id</label>
  </div>

  <button  className='generate-btn' type='submit' style={{backgroundColor:'#16AEE8'}}>Generate tag</button>    
    </div>


    </form>

   
    {generatedQRCode && (
        <div>
          <hr />
          <br />
          <button onClick={handlePrint} className='print-tag' style={{backgroundColor:'#16AEE8'}}>Print tag</button>
          {/* <Link style={{textDecoration:'none'}} to={`/doorbellscanner/${userid}`}>
          <button className='print-tag'>View the scanners page</button>
          </Link> */}
        </div>
      )}
{generatedQRCode && (
        <div className='door-template' style={{display:'none'}}>
           <div  ref={componentRef}>
         <div >
          <div className='door-template'><QRCode value={generatedQRCode} className='main-codep3' />
          
          <img src="https://i.postimg.cc/rwdfSHJ9/door-empty.png" alt="tagprint" className='template-door'/>
          </div>
         </div>
    </div>
        </div>
      )}

    


  </div>
  <br /><br />

  </div>
 
  </>
}

export default Doorbellgenerator
