import React from 'react'

import {useParams} from 'react-router-dom';

import {JitsiMeeting} from '@jitsi/react-sdk'
const Jitsimeetfordoorbell = () => {

  const params=useParams();
  const userid=params.userid;


  return <>

    <JitsiMeeting
    roomName = { userid } 
    getIFrameRef = { node =>
         {node.style.height = '800px';
         node.style.width = '100%'
        } }
    configOverwrite = {{
        startWithAudioMuted: true,
        hiddenPremeetingButtons: []
    }} 
/>
  </>
}

export default Jitsimeetfordoorbell;
