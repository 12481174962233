import React from 'react'
import './Home.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
import MyCarousel from '../Carousel/Carousel'

const Homepage = () => {


  return <>
  <div className='bck-box'>
   <div className='flowimg'>
  <img src="https://mypin.in/static/images/shapes.svg" alt=""
     className='bck-img'
    />
   </div>
  <div className='home-container'>
      {/* <div className='backgroundimg'>
         <img src="https://i.postimg.cc/J48zKWcz/backgroundimg.png" alt="" />
      </div> */}

      <div className='banner'>
         <div className='bannerhead' style={{color:'white'}}>MY PIN</div>
         <div className='subhead' style={{color:'white'}}>Stay connected</div>
         <div className='bannerdisc' style={{color:'white',fontWeight:'lighter'}}>
         Let people contact you incase of any emergency digitally without any hassle.
         <span style={{color:'white'}}>Just scan the qr code and connect with the owner without shairing any personal details.</span>
         </div>

      </div>
     
        <div className='vehicle-box'>
        <div className='img-vehicle'>
            
              <img 
            src="https://i.postimg.cc/28p5zF41/vehicleparking.png"
             alt="" 
             className='core-img'
             />
        
        </div>
        <div className='vehicle-know-box'>
         <div className='vehicle-know-head' style={{color:'white'}} >
            Vehicle Parking Tags</div>
            <div className='vehicle-know-info' style={{color:'white',fontWeight:'lighter'}}>
            Vehicle parking tags are used for efficient management of parking spaces. They help in identifying authorized vehicles, ensuring proper utilization of parking resources, and streamlining enforcement.
            </div>
            <Link to='/vehicletags' style={{textDecoration:"none"}}> 
            <button className='more-btn  more-btn2'>
               
               Know More <span className='moreicon'>{'>>'}</span></button>
            </Link>
        </div>
        </div>

        <div className='door-box'>
             <div className='door-know-box'>
             <div className='vehicle-know-head'>
            <span style={{color:'white'}}>Door Tags</span></div>
            <div className='vehicle-know-info' style={{color:'white',fontWeight:'lighter'}}>
            Door QR codes are used for secure access control, enabling quick entry to buildings or rooms. They streamline visitor management, enhance security, track entry/exit times, and facilitate contactless check-ins for improved efficiency.
            </div>
            <Link to='/doortags' style={{textDecoration:'none'}}>
            <button className='more-btn'>
               Know More
            <span className='moreicon'>{'>>'}</span>
            </button>
            </Link>

        </div>
        <div className='img-door'>
        <img 
            src="https://i.postimg.cc/5y8hS2yy/Designer-7.png"
             alt="" 
             className='core-img2'
             />
        </div>
        </div>

        <div className='whychoosebox'>
         <div className='whyhead' style={{color:'white'}}>Why Choose Us &nbsp;?<span style={{color:'#16AEE8'}}></span></div>
         <div className='whybox'>
         <div className="whyboxes">
            <div className='whybox1'>
            <img src="https://i.postimg.cc/qqBgNMSZ/Designer-2.png" alt="" className='whyimg' />
            </div>
            <div className='whybox2'>
              24X7 Free Problem Resolution

            </div>
         </div>
         <div className="whyboxes">
            <div className='whybox1'>
            <img src="https://i.postimg.cc/RZpWLt0f/Designer-1.png" alt="" className='whyimg' />
            </div>
            <div className='whybox2'>
            Best Service to Customers
            </div>
         </div>
         <div className="whyboxes">
            <div className='whybox1'>
            <img src="https://i.postimg.cc/65qR5YdL/Designer-3.png" alt="" className='whyimg' />
            </div>
            <div className='whybox2'>
               Fast Delivery
            </div>
         </div>
         <div className="whyboxes">
            <div className='whybox1'>
            <img src="https://i.postimg.cc/htTGS5tY/Designer-4.png" alt="" className='whyimg' />
            </div>
            <div className='whybox2'>
              Affordable Prices
            </div>
         </div>
         </div>
        </div>

        <div className='reviewbox'>
         <div className='reviewhead' style={{color:'white'}}>What our customers say about our service?</div>
         <div>
            <MyCarousel/>
         </div>

        </div>
        <div className='footer-res'>
     <Footer/>
     </div>
     </div> 

  </div>
        
  </>
}

export default Homepage
