import React from 'react'
import './Carparkingqrcode.css'
import Footer from '../Footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'

import { auth } from '../Auth/firebase';


const Carparkingqrcode = () => {

  const navigate=useNavigate();

  const gotovehicletags=()=>{

    if(auth.currentUser){
      navigate(`/vehicletaggenerator/${auth.currentUser.uid}`);
    }
    else{
       navigate('/login');
    }
   }
   const sendmessage=()=>{
    var phoneNumber = '919991942662'; // Replace with your phone number
  var message = 'Hello! I would like to purchase a vehicle parking tag for my vehicle. Please do tell me what are the next steps.'; // Replace with your default message
  var whatsappLink = 'https://wa.me/' + phoneNumber + '?text=' + encodeURIComponent(message);

  // Open the WhatsApp link in a new tab
  window.open(whatsappLink, '_blank');
    

   }
  return <>
  <div className='bck-box2'>
  <img src="https://mypin.in/static/images/shapes.svg" alt=""
     className='bck-img'
    />
  <div  className='car-main'>
    <div className='car-qr-info'>
        <div className='car-parking-head'> 
        <div style={{color:'white'}}>Vehicle Parking Tags</div> 
        </div>
        <div className='car-parking-info'
           style={{color:'#16AEE8',marginTop:'4%'}}
        >
            
          <div style={{color:"white",fontWeight:'lighter'}} className='head22'>
             Let people contact you incase of any issue with your parked vehicle.

            </div> 
            <div className=' more-info2' style={{color:"white",fontWeight:'lighter'}}>
Your Phone number is not shared, and Any one with a Smart phone can contact you for any issues with your parked vehicle. .
            </div>
        </div>
        <div style={{textDecoration:'none'}}>
        <button className='car-qr-btn hoverit' onClick={gotovehicletags}>GENERATE NOW</button>
        </div>
    </div>
     <img 
     src="https://mypin.in/static/images/landing.png" 
     alt="car qr img"
     className='car-qr-img'
      />
    </div>

    <div className='how-container'>
   
      <img src="https://i.postimg.cc/02kjgyrs/vehicleowner.png" alt="how it works img" className='phone-img'/>
       <div className='solution-box' >
        <div className='solution1' style={{color:'white'}}>
        How it works?<span style={{color:'#16AEE8'}}></span>
        </div>
        <div className='solution2' style={{color:"white",fontWeight:'lighter'}}>
        Found anything wrong with the vehicle?
        </div>
        <div className='solution3' style={{color:"white",fontWeight:'lighter'}}>
        Scan the QR code on the vehicle and contact with the 
              vehicle owner by sms , whatsapp messaging or masked call.
              Don't worry.... your contact details will not be shared.
        </div>
       </div>
    </div>

    <div className='pricecontainer2'>
      
      <div className='pricebox' style={{backgroundColor:'transparent'}}>
        <div className='pricehead' style={{color:'white'}}>Price ...</div>
        <div className='priceinfo' style={{color:"white",fontWeight:'lighter'}}>Rs. 500 / tag</div>
        <div className='pricedisc' style={{color:"white",fontWeight:'lighter'}}>Each vehicle tag worths just rupees 500 but can save a lot of your money by avoiding milacious things to happen. </div>
        <div className='ownnowbtn'onClick={()=>sendmessage()}>Own Now</div>
      </div>

      <div className='tagshow'>
        <img src="https://i.postimg.cc/wvyHdfF4/vehicletag1.png" alt="pricetagimg" className='pricetagimg'/>
      </div>
    </div>
    <div className='margin1'>
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br />
    </div>
    <div className='margin2'>
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
<br /><br /><br /><br />
    </div>
    
      <Footer/>
  </div>
    
  </>
}

export default Carparkingqrcode
