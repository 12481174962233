import React, { useState, useEffect } from 'react';
import './Popup.css'; // Create a CSS file for styling

const Adminpopup = ({ isOpen, onClose }) => {
  
  useEffect(() => {
    let timeout;

    if (isOpen) {
      // Set a timeout to close the popup after 3 seconds
      timeout = setTimeout(() => {
        onClose();
      }, 3000);
    }

    // Clear the timeout when the component unmounts or when isOpen becomes false
    return () => clearTimeout(timeout);
  }, [isOpen, onClose]);

  return (
    <div className={`popup ${isOpen ? 'open' : ''}`}
     style={{backgroundColor:'#16AEE8'}}
    >
      <p style={{color:'white'}}>Making Changes...</p>
    </div>
  );
};

export default Adminpopup;
