import React, { useEffect, useRef, useState } from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import './Doorbellscannerpage.css'
import Popup from '../Popup/Popup'
import emailjs, { send } from 'emailjs-com';
import Popup2 from '../Popup/Popup2';
import { auth } from '../Auth/firebase';


const Doorbellscanner = () => {
  const param=useParams();

  const userid=param.userid;

   const [email,setemail]=useState('');

  const [house,sethouse]=useState('');

    const navigate=useNavigate();

    const [quesidx,setquesidx]=useState();
    const [query,setquery]=useState();
    const [custommsg,setcustommsg]=useState();
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupOpen2, setPopupOpen2] = useState(false);

    const ques=[
        {
        data:'Delivery boy'
        },
        {
            data:'Neighbour'
         },
            {
                data:'Friend/Family member'
                },
                {
                    data:'Security guard'
                    },
                    {
                        data:'Other'
                        }]

const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleClosePopup2 = () => {
    setPopupOpen(false);
  };

  const fetchusercred=async()=>{
    const response= await fetch(`https://doapi.mypin.in/getdoorbelldata/${userid}`,{
      method:"GET",
            headers:{
              'content-type':'application/json',
              'Access-Control-Allow-Origin': '*'
            }
    });
    const data= await response.json();
     setemail(data.email);
    sethouse((data.house));
  }

  useEffect(()=>{
    fetchusercred();
  })


  const sendmailforcall=()=>{
    const to=email;
    const subject='Doorbell Alert';
    const message=`someone is standing at your door and wants to connect with you as he/she found no response from the other side of the door. Please open jitsi meet and start the call using ${userid} as the room id .`

    const serviceId = 'service_xlhg6gu';
    const templateId = 'template_z8wvcsj';
    const api = '-e3QTwnjEurEt2MLT';


    emailjs.send(serviceId, templateId, { to, subject, message }, api)
      .then((response) => {
        console.log('Email sent successfully:', response);
        navigate(`/startmeetfordoorbell/${userid}`);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }
  const sendtxtmsg=()=>{
    const to=email;
    const subject='Doorbell Alert';
    const message=`A ${query} is waiting at your door for a long time as he/she found no response from the other side of the door. Please open the door to contact.`;

    const serviceId = 'service_xlhg6gu';
    const templateId = 'template_z8wvcsj';
    const api = '-e3QTwnjEurEt2MLT';
   setPopupOpen2(true);
    emailjs.send(serviceId, templateId, { to, subject, message }, api)
    .then((response) => {
      setPopupOpen2(false);
      setPopupOpen(true);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  }

    const startmeet=()=>{
        sendmailforcall();
    }


    const setcustommessage=(ev)=>{
        setcustommsg(ev.target.value);
        setquery(custommsg);
     
    }

  return <>
    <div className={`popup-overlay ${popupOpen ? 'open' : ''}`} onClick={handleClosePopup}></div>
      <Popup isOpen={popupOpen} onClose={handleClosePopup} />

      <div className={`popup-overlay ${popupOpen2 ? 'open' : ''}`} onClick={handleClosePopup2}></div>
      <Popup2 isOpen={popupOpen2} onClose={handleClosePopup2} />
  <div className='main-scanner-box'>
     <div className='scanner-head'>
        MY PIN
        </div> 
        <div className='head2'>
            Contact House Owner !!
        <hr />
        <div className='vehicle-no'>
         &nbsp; {house}
        </div>
        <hr />
        <div className='scanner-ques'>Please select the reason why do you want to contact with the owner of this house .</div>
        </div>
        <hr />
         
        <div className='ques-box'>

        {ques.map((que,index)=>{
            return <>
            <div key={index} className='ques'
            onPointerOver={()=>setquery(que.data)}
             onClick={(ev)=>{setquesidx(index);setquery(que.data);console.log(query)}} value={que.data}>
               <div className='quest'
               key={index}
               style={quesidx===index?{backgroundColor:'#168EE8',color:'white'}:{}}
               >
                <div style={{padding:'3%'}}>
                  <div
                  style={quesidx===index?{color:'white'}:{}}
                  >
                {que.data}
                    </div>
                </div>
               </div>
            </div>
            </>
        })}
        <div>
            <div className='ques'>Custom message</div>
            <textarea  
            cols="40" rows="5" 
            onChange={(ev)=>setcustommessage(ev)} 
            onInputCapture={(ev=>setcustommessage(ev))}
            value={custommsg} 
            >

            </textarea>
        </div>
         
         <div className='btns'>
        <div className='actionbtn' onClick={sendtxtmsg}>
            Send message
        </div>

        <div className='actionbtn'>
        
          <div onClick={startmeet} style={{color:'white'}}>Video call</div>
        </div>
        
    </div>
       <hr />
    <div className='footer-txt'>
        Please make sure you are sending correct message . You are helping us to create a healthy and safer community for yourself and for those around you.
    </div>
    <div className='footer-brand'>
        Thankyou for using mypin. For more info , login to mypin.in
    </div>

    <div>
    <img alt="MyPin" src="https://mypin.in/content/mypinlogo-(2).png" className="logo-imge"/>
    </div>
        </div>
     </div>
  </>
}

export default Doorbellscanner
