import React from 'react'
import './Doortags.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../Auth/firebase'
const Doortags = () => {

  const navigate=useNavigate();

   const gotodoortag=()=>{
    if(auth.currentUser){
      navigate(`/doorbellgenerator/${auth.currentUser.uid}`);
    }
    else{
       navigate('/login');
    }
   }
   const sendmessage=()=>{
    var phoneNumber = '919991942662'; // Replace with your phone number
  var message = 'Hello! I would like to purchase a door bell tag. Please do tell me what are the next steps.'; // Replace with your default message
  var whatsappLink = 'https://wa.me/' + phoneNumber + '?text=' + encodeURIComponent(message);

  // Open the WhatsApp link in a new tab
  window.open(whatsappLink, '_blank');
    

   }
  return <>
     {/* <div className='car-qr'>
  </div> */}
  <div className='bck-box2'>
  <img src="https://mypin.in/static/images/shapes.svg" alt=""
     className='bck-img'
    />
  <div  className='car-main'>
    <div className='car-qr-info'>
        <div className='car-parking-head'><span style={{color:'white'}}>Doorbell Tags</span> </div>
        <div className='car-parking-info'
           style={{color:'#003c71',fontSize:'1.4vw',marginTop:'4%'}}
        >
            
          <div style={{color:"white",fontWeight:'lighter'}} className='head22'>
          Leave message for people when you are not home. Allow delivery agents call you when they are at your door.

            </div> 
            <div className='more-info2 head22' style={{color:'white',fontWeight:'lighter'}}>

            Manage your door bell when you are not at home. Voice, and Video call. With WhatsApp updates coming soon.
            </div>
        </div>
        <div style={{textDecoration:'none'}} onClick={gotodoortag}>
        <button className='car-qr-btn hoverit'>GENERATE NOW</button>
        </div>
    </div>
     <img 
     src="https://mypin.in/static/images/landing.png" 
     alt="car qr img"
     className='car-qr-img'
      />
    </div>
    <div className='how-container'>
   
      <img src="https://i.postimg.cc/8zJVx7Qs/MY-PIN-7.png" alt="how it works img" className='phone-img'/>
       <div className='solution-box' >
        <div className='solution1'>
       <span style={{color:'white'}}> How it works?</span>
        </div>
        <div className='solution2'
        style={{color:"white",fontWeight:'lighter'}}
        >
        No reply from other side of the door?
        </div>
        <div className='solution3 ' style={{color:"white",fontWeight:'lighter'}}>
        Scan the QR code on the door and contact with the 
              house owner by sms , whatsapp messaging or masked call.
              Don't worry.... your contact details will not be shared.
        </div>
       </div>
    </div>
    <div className="doorpricebox">
      <div className='doorpriceinfobox'>
        <div className='doorphead' style={{color:'white'}}>Price</div>
        <div className='dprice' style={{color:"white",fontWeight:'lighter'}}>Rs. 500 / tag</div>
        <div className='doorpdisc' style={{color:"white",fontWeight:'lighter'}} >
        Each door tag worths just rupees 500 but can save a lot of your money by avoiding milacious things to happen. 
        </div>
        <div className='ownnowbtn' onClick={sendmessage}>Own Now</div>
      </div>
      <div className='doortagimgbox'>

        <img src="https://i.postimg.cc/T3nvQ51F/doortag1.png" alt="door-tag" className='doortagimg' />
      </div>


    </div>
    <div className='margin1'>
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
   <br /><br /><br /><br />
   

    </div>
    <div className='margin2'>
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br /><br />
    <br /><br /><br /><br /><br /><br />
<br /><br /><br />
    </div>
      <Footer/>
  </div>
    
  </>
}

export default Doortags
