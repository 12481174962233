// import React, { useState } from 'react';
// import QrReader from 'react-qr-scanner';

// import './Scanner.css'

// const Qrscanner = () => {
//   const [result, setResult] = useState('');

//   const [facingMode, setFacingMode] = useState('rear');

//   const toggleFacingMode = () => {
//     setFacingMode(facingMode === 'rear' ? 'front' : 'rear');
//   };

//   const handleScan = (data) => {
//     if (data) {
//       setResult(data);
//     }
//   };

//   const handleError = (err) => {
//     console.error(err);
//   };
 

//   return <>
//   <div>

//   <div className='reader'>
//     <QrReader
//       delay={300}
//       onError={handleError}
//       onScan={handleScan}
//      facingMode={facingMode}
//       style={{width:'100%'}}
//     />
//   </div>
//   <hr />
//   <button onClick={toggleFacingMode} className='togglecambtn'>Toggle Camera</button>
//   <p>{result}</p>
//   <hr />
//   <br />
// </div>


  
//   </>
  
// };

// export default Qrscanner;

import React, { useState, useRef, useEffect } from 'react';
import QrReader from 'react-qr-scanner';

const QRScanner = () => {
  const [result, setResult] = useState('');
  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const scannerRef = useRef(null);

  useEffect(() => {
    // Fetch available camera devices
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const cameras = devices.filter(device => device.kind === 'videoinput');
        setCameraDevices(cameras);
        // Automatically select the rear camera if available
        const rearCamera = cameras.find(camera => camera.label.toLowerCase().includes('back') || camera.label.toLowerCase().includes('rear'));
        if (rearCamera) {
          setSelectedDeviceId(rearCamera.deviceId);
        } else {
          // If no rear camera found, select the first available camera
          setSelectedDeviceId(cameras[0]?.deviceId || null);
        }
      })
      .catch(err => console.error('Error enumerating devices:', err));
  }, []);

  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleDeviceChange = (e) => {
    setSelectedDeviceId(e.target.value);
  };

  return (
    <div style={{ height: '500px',position:'absolute',marginTop:'30%'}}>
      <div>
        <select value={selectedDeviceId} onChange={handleDeviceChange}>
          {cameraDevices.map(device => (
            <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
          ))}
        </select>
        <hr />
        <QrReader
          ref={scannerRef}
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%', height: '100%' }}
          facingMode={null}
          deviceId={selectedDeviceId}
        />
      </div>
      <p>{result}</p>
    </div>
  );
};

export default QRScanner;
