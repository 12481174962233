import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import './Navbar.css'
import { auth } from "../Auth/firebase";

const Navbar = ({isloggedin,setisloggedin}) => {

   const navigate=useNavigate();

  const [toggle, setToggle] = useState(false);
  const [togglec, setTogglec] = useState(false);

  const gotovehicletags=()=>{
   if(!auth.currentUser){
      navigate('/vehicletags')
   }
   else{
      navigate(`/admin/vehicletaggenerator`);
   }
  }

  const gotodoortag=()=>{
   if(!auth.currentUser){
      navigate('/doortags');
   }
   else{
      navigate(`/admin/doorbellgenerator`);
   }
  }
  const gotovehicletagcust=()=>{
 
      navigate(`/admin/vehiclecustomers`);
  }

  const gotodoortagcust=()=>{
   
 
      navigate(`/admin/doorcustomers`);
  }

  const logout=()=>{
   auth.signOut()
   .then(result=>{
      navigate('/');
   })
  }
  return <>
         <div className="nav-container">
          <div>
            <div className="logo"><img alt="MyPin" src="https://mypin.in/content/mypinlogo-(2).png" className="img-responsive"/></div>
            {/* <img src="https://w3axis.com/wp-content/uploads/2023/09/w3axis-white-circle-logo.png" alt="" className="logo" /> */}
          </div>
          <div className="nav-ele">
            <Link 
            to='/'
            className="nav-ele-div"
            style={{textDecoration:"none"}}
            >
            Home
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="nav-ele-div" onClick={() => {setToggle(!toggle);setTogglec(false)}}>Solutions
            
            {/* <img width="24" height="24" src="https://img.icons8.com/material/24/give-way--v1.png" alt="give-way--v1" className="arrow-down"/> */}
            <img width="24" height="24" src="https://img.icons8.com/color/48/triangle-stroked.png" alt="give-way--v1" className="arrow-down"/>
            
            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {
               auth.currentUser?<>
              <div className="nav-ele-div" onClick={()=>{setTogglec(!togglec);setToggle(false)}}>Customers
              <img width="24" height="24" src="https://img.icons8.com/color/48/triangle-stroked.png" alt="give-way--v1" className="arrow-down"/>
              </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               </>:
               <>
               {/* <div className="nav-ele-div" onClick={()=>{
                     window.open("https://www.google.com/search?q=qr+code+scanner", '_blank');
                 
               }}>Other</div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  */}
               </>
            }
            
            {!auth.currentUser?<>
            <Link to='/login' className="purchase-now" style={{color:'16AEE8',textDecoration:'none'}}>
              Login
            </Link> 
            </>:
            <>
            <div onClick={logout} className="purchase-now">
              Log Out
            </div> 
            </>}
            
          </div>
      {toggle && (
        <ul className="list-group ">
          <br />
  
       <div onClick={gotodoortag} style={{textDecoration:'none'}} >
            <li className="list-group-item">
            <div className="svgbox">
            <img width="30" height="30" src="https://img.icons8.com/ultraviolet/40/jingle-bell.png" alt="jingle-bell"/>
            </div>
         <div className="option-name">
            Door Bell Tags
         </div>
         <div className="list-info">
          Never Expiring door bell qr codes.
         </div>
            </li>
       </div>
        
        <div onClick={gotovehicletags} style={{textDecoration:'none'}}>
            <li className="list-group-item">
            <div className="svgbox">
            <img width="30" height="30" src="https://img.icons8.com/external-beshi-line-kerismaker/48/external-Car-Parking-garage-beshi-line-kerismaker.png" alt="external-Car-Parking-garage-beshi-line-kerismaker"/>
            </div>
         <div className="option-name">
            Vehicle Parking Tags
         </div>
         <div className="list-info">
         Car parking tags simplify entry and connection processes.
         </div>
            </li>
        </div>

        </ul>
      )}

{togglec && (
   <div className=" listgp2">
        <ul className="list-group">
          <br />
  
       <div onClick={gotodoortagcust} style={{textDecoration:'none'}} >
            <li className="list-group-item">
            <div className="svgbox">
            <img width="30" height="30" src="https://img.icons8.com/ultraviolet/40/jingle-bell.png" alt="jingle-bell"/>
            </div>
         <div className="option-name">
            D-Tag Customers
         </div>
         <div className="list-info">
          Never Expiring door bell qr codes.
         </div>
            </li>
       </div>
        
        <div onClick={gotovehicletagcust} style={{textDecoration:'none'}}>
            <li className="list-group-item">
            <div className="svgbox">
            <img width="30" height="30" src="https://img.icons8.com/external-beshi-line-kerismaker/48/external-Car-Parking-garage-beshi-line-kerismaker.png" alt="external-Car-Parking-garage-beshi-line-kerismaker"/>
            </div>
         <div className="option-name">
            V-Tag Customers
         </div>
         <div className="list-info">
         Car parking tags simplify entry and connection processes.
         </div>
            </li>
        </div>
        
        </ul>


   </div>
      )}

         </div>
         <Outlet/>
  
  </>
};

export default Navbar;
