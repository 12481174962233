import React, { useState } from 'react';
import "rsuite/dist/rsuite.min.css"
import { Carousel } from 'rsuite';
import './Carousel.css'

const MyCarousel = () => {
  // Use state to store the current slide index
  const [index, setIndex] = useState(0);

  // Define an array of images to display in the carousel
  const images = [
    'https://i.imgur.com/1.jpg',
    'https://i.imgur.com/2.jpg',
    'https://i.imgur.com/3.jpg',
    'https://i.imgur.com/4.jpg',
  ];

  // Handle the change event when the user clicks on the arrows or dots
  const handleChange = (value) => {
    setIndex(value);
  };

  return (
    <div className='carouselbox' >
       <Carousel 
       autoplay
       >
      <img src="https://i.postimg.cc/tRc6ptZx/review1.png" alt="Slide 1" />
      <img src="https://i.postimg.cc/4yBy2c7s/review2.png" alt="Slide 2" />
      <img src="https://i.postimg.cc/tCs9Gw8h/review3.png" alt="Slide 3" />
    </Carousel>
    </div>
  );
};

export default MyCarousel;
