import React from 'react'

const Footer = () => {
  return <>
     <div className='footer'>
        <div>
            <div className='footer-logo'>
            <img alt="MyPin" src="https://mypin.in/content/mypinlogo-(2).png" className="f-logo"/>
            <div className='profile'>
                Your Business Pofile
            </div>
            </div>
            <div className='footer-solutions'>
               <div className='sol-head'>Solutions</div>
               <div className='sols'>Url Shortener</div>
               <div className='sols'>QR Code Generator</div>
               <div className='sols'>NFC</div>
               <div className='sols'>Vehicle Parking Tags</div>
               <div className='sols'>Doorbell Tags</div>
            </div>
            <div className='footer-resources'>
            <div className='resources-head'>Resources</div>
               <div className='resources'>Help Center</div>
               <div className='resources'>Developer API</div>
               <div className='resources'>Contact Us</div>
                 
            </div>
        </div>
     </div>
  
  </>
}

export default Footer
