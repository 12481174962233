import React from 'react';
import { BrowserRouter, Link, Outlet, Route, Router, Routes } from 'react-router-dom';
import './App.css';

import Home from './Home';
import Doortags from './doortags/Doortags';
import Vehicletagsgenerator from './Vehicletagsgenerator/Vehicletagsgenerator';
import Vehiclescannerpage from './Vehiclescannerpage/Vehiclescannerpage';
import Jitsimeet from './Jitsimeet/Jitsimeet';
import Doorbellscanner from './Doorbellscanerpage/Doorbellscannerpage';
import Doorbellgenerator from './Doorbellgenerator/Doorbellgenerator';
import Jitsimeetfordoorbell from './Jitsimeetfordoorbell/Jitsimeetfordoorbell';
import Carparkingqrcode from './Carparkingqr/Carparkingqrcode';
import Homepage from './Homepage/Homepage';
import Login from './Auth/Login';
import Vehiclecustomers from './Customers/Vehiclecustomers';
import Sidebar from './Admin/sidebar';
import Doorcustomers from './Customers/Doorcustomers';
import Navbar from './Navbar/Navbar';
import Qrscanner from './Scanner/QrScanner';






function App() {
 
  return <>
    <div className="App">
      <BrowserRouter>
       <Routes>
        <Route element={<Navbar/>}>
          <Route path='/login' element={<Login/>}/>
          <Route path='/' element={<Homepage/>}/>
          <Route  path='/vehicletags' element={<Carparkingqrcode/>}/>
          <Route path='/doortags' element={<Doortags/>}/>
         
          <Route path='/startmeet/:userid' element={<Jitsimeet/>}/>
          <Route path='/startmeetfordoorbell/:userid' element={<Jitsimeetfordoorbell/>}/>
          <Route path='/qrscanner' element={<Qrscanner/>}/>
        </Route>
        
          <Route path={`/vehiclescanner/:userid`} element={<Vehiclescannerpage/>}/>
          <Route path={`/doorbellscanner/:userid`} element={<Doorbellscanner/>}/>

          <Route element={<Sidebar/>}>
          <Route path='/admin/vehicletaggenerator' element={<Vehicletagsgenerator/>} />
          <Route path='/admin/doorbellgenerator' element={<Doorbellgenerator/>} />
          <Route path='/admin/vehiclecustomers' element={<Vehiclecustomers/>}/>
          <Route path='/admin/doorcustomers' element={<Doorcustomers/>}/>

          </Route>
       </Routes>
      </BrowserRouter>
    
    </div>
  
  </>
  
}

export default App;
