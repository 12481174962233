import React from 'react'

import {useParams} from 'react-router-dom'

import {JitsiMeeting} from '@jitsi/react-sdk'
const Jitsimeet = () => {

  const params=useParams();
  const userid=params.userid;
  return <>

    <JitsiMeeting
    roomName = { userid } 
    getIFrameRef = { node =>
         {node.style.height = '800px';
         node.style.width = '100%'
        } }
    configOverwrite = {{
        startWithAudioMuted: true,
        disableModeratorIndicator: true,
        hiddenPremeetingButtons: [],
        
    }} 
    interfaceConfigOverwrite = {{
      DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
  }}
  
  userInfo={{
    displayName:'My Pin'
  }}

/>
  </>
}

export default Jitsimeet;
