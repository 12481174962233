import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar';

import {useNavigate} from 'react-router-dom'
import {createUserWithEmailAndPassword } from "firebase/auth";
import {signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import './Login.css'

const Login = () => {
    const navigate=useNavigate();
    const [ipemail,setipemail]=useState('');
    const [ippassword,setippassword]=useState('');
    const [err,seterr]=useState(false);
    const [isloggedin,setisloggedin]=useState(false);
    const login=()=>{

        // createUserWithEmailAndPassword(auth,ipemail,ippassword)
        // .then(()=>{
        //     seterr(true);
        // })
        signInWithEmailAndPassword(auth, ipemail, ippassword)
      .then(()=>{
        navigate(`/admin/vehiclecustomers`);
      })
        .catch(()=>{
            seterr(true);
        })
    }
  
  return <>
  <div className='login-back'>
    <img src="https://i.postimg.cc/ZKyK5nvd/MY-PIN-8-1.png" alt="loginbackimg"  className='loginanim' />
    <img src="https://i.postimg.cc/ZKyK5nvd/MY-PIN-8-1.png" alt="loginbackimg"  className='loginanim2' />
  <div className='logincontainer'>
    <div className='login-head' style={{backgroundColor:'#16AEE8'}}>Admin Login</div>
  {err &&
    <div className='errorbox' >Invalid email or password...</div>
  }

    <div className='authcontainer'>

    <div class="input-container">
    <input type="email" id="input1" required value={ipemail} onChange={ev=>setipemail(ev.target.value)} className='ip'style={{backgroundColor:'#142c43',color:'white'}} />
    <label for="input1" style={{backgroundColor:'#142c43',color:'#16AEE8'}}>Email Id</label>
  </div>

  <div class="input-container">
    <input type="password" id="input2" required value={ippassword} onChange={ev=>setippassword(ev.target.value)} className='ip' style={{backgroundColor:'#142c43',color:'white'}}/>
    <label for="input2" style={{backgroundColor:'#142c43',color:'#16AEE8'}} >Password</label>
  </div>
    <button className='loginbtn' onClick={login}  style={{backgroundColor:'#16AEE8'}}>Login</button>
    </div>
  </div>

  </div>

 
  
  
  </>
}

export default Login
