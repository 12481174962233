import React, { useEffect, useState } from 'react'
import './Admin.css'
import Navbar from '../Navbar/Navbar'
import { Link, useNavigate,Outlet } from 'react-router-dom';
import { auth } from '../Auth/firebase';

const Sidebar = () => {
    const navigate=useNavigate();
    const [active,setactive]=useState('v');
  

    useEffect(()=>{
       if(!auth.currentUser){
        navigate('/login')
       }

    },[navigate,auth])

    const logout=()=>{
        auth.signOut()
        .then(result=>{
           navigate('/');
        })
       }

  return <>
    <div className='sidebar'>
        <div className='iconbox'>
            <img src="https://mypin.in/content/mypinlogo-(2).png" alt="" className='img-responsive icon' />
        </div>
       <div className='sidebarbox'>
       <Link to={`/admin/vehicletaggenerator`} style={{textDecoration:'none'}}>
        <div className="sideele" 
      
        onClick={()=>{setactive('vg')}}
        style={active==='vg'?{backgroundColor:'white',color:'#16AEE8'}:{}}
        
        >
            Vehicle Tag Generator
            </div>
        </Link>
        <Link to={`/admin/doorbellgenerator`} style={{textDecoration:'none'}}>
        <div className="sideele" 
         
        onClick={()=>setactive('dg')}
      
        style={active==='dg'?{backgroundColor:'white',color:'#16AEE8'}:{}}
        >
             Door Tag Generator
            </div>
        </Link>

        <Link to={`/admin/vehiclecustomers`} style={{textDecoration:'none'}}>
        <div className="sideele" 
      
        onClick={()=>setactive('v')}
     
        style={active==='v'?{backgroundColor:'white',color:'#16AEE8'}:{}}
        
        >
            Vehicle Tags Customers
            </div>
        </Link>
            <Link to={`/admin/doorcustomers`} style={{textDecoration:'none'}}>
        <div className="sideele" 
    
        onClick={()=>setactive('d')}
        style={active==='d'?{backgroundColor:'white',color:'#16AEE8'}:{}} 
        >
        Door Tags Customers
        </div>
            
            </Link>
           
        <div className="sideele" 
        onClick={()=>{
        logout();
    }
    }
        style={{backgroundColor:'#006E99',color:'white'}}
        
        >
          Log Out
            </div>

       </div>
    </div>
    <Outlet/>
  </>
}

export default Sidebar;
